<template>
    <div class="home-main">
        <!-- 今日热点 -->
        <div class="home-hot">
            <div class="hot-banner">
                <swiper :options="swiperOption">
                    <swiper-slide v-for="(item,index) in Information_hot1" :key="item.id" @click.native="toPath(`/actciles/${item.id}/detail`)">
                        <div class="hot-banner-img">
                            <el-image
                                    style="width: 100%; height: 100%"
                                    :src="item.img"
                                    fit="cover"></el-image>
                        </div>
                        <div class="hot-banner-title">{{ item.title }}</div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
            <div class="hot-today">
                <div class="hot-today-title">
                    <span>今日热门</span>
                </div>
                <div class="hot-today-main">
                    <div class="hot-today-item" v-for="(item,index) in Information_hot2">
                        <router-link :to="`/actciles/${item.id}/detail`">{{ item.title }}</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- 直播课程 -->
        <div class="home-live">
            <plate-title title="直播课程" desc="大咖操刀，线上直播，医生足不出户学习最先进的手术理念与手法"></plate-title>

            <div class="home-live-main" v-if="liveList.length>0">
                <div class="home-live-one" @click="toPath((liveList[0].live_state == 1 || liveList[0].live_state == 2)?`/seminars/${liveList[0].id}/detail`:`/seminars/${liveList[0].vid || liveList[0].id}/details`)">
                    <div class="home-live-one-img">
                        <img :src="liveList[0].image">
                    </div>
                    <div class="home-live-one-other">
                        <div class="home-live-one-title">{{ liveList[0].title }}</div>
                        <div class="home-live-one-time">{{ liveList[0].start_time }}</div>
                        <div class="home-live-one-desc">
                            {{ liveList[0].price == '0.00'?'免费': liveList[0].price }}
                        </div>
                    </div>

                </div>

                <div class="home-live-other">
                    
					<!-- <live-course-item v-for="(item,index) in liveList" :key="item.id" v-if="index>0 && index<7" :item="item" @click.native="toPath((item.live_state == 1 || item.live_state == 2)?`/seminars/${item.id}/detail`:`/seminars/${item.vid || item.id}/details`)"></live-course-item> -->
					<live-course-item v-for="(item,index) in liveList" :key="item.id" v-if="index>0 && index<7" :item="item" @click.native="toLive(item)"></live-course-item>
                
				
				</div>
            </div>

            <see-more path="/seminars"></see-more>
        </div>
        <!-- 收费课程 -->
        <div class="home-payCourse">
            <plate-title title="会员课程" desc="精心自制的学习视频 / 一次付费随时观看"></plate-title>
            <div class="home-payCourse-main">
                <div class="home-payCourse-left">
                    <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/left@2x.png"/>
                    <div class="home-payCourse-left-other">
                        <h3>会员课程</h3>
                        <router-link to="/courseVip">
                            发现精彩好课
                        </router-link>
                    </div>
                </div>
                <div class="home-payCourse-other">
                    <pay-course-item v-for="(item, index) in payCourseList" :item="item" :key="item.id" v-if="index<8" @click.native="toPath(`/courseVip/${item.cid}/detail`)"/>
                </div>
            </div>

            <see-more path="/courseVip"></see-more>
        </div>
        <!-- 最新课程 -->
        <div class="home-newCourse">
            <plate-title title="最新课程" desc="专家讲课、手术视频讲解/第一时间为您推荐更多优质内容"></plate-title>
            <div class="home-newCourse-main">
                <swiper :options="swiperOption2" class="home-newCourse-swiper">
                    <swiper-slide v-for="(item,index) in newCourseList" :key="item.cid" class="home-newCourse-item">
                        <div class="home-newCourse-item-box" @click="toPath(`/lct/${item.cid}/detail`)">
                            <div class="home-newCourse-item-img"><img :src="item.banner"></div>
                            <div class="home-newCourse-item-right">
                                <div class="home-newCourse-item-title">{{ item.title }}</div>
                                <div class="home-newCourse-item-desc">
                                    <div class="home-newCourse-item-num"><span style="margin-right: 50px">{{ item.author }}</span>{{ item.study_count }}人学过</div>
                                    <div class="home-newCourse-item-price">{{ item.price == '0.00'?'免费':item.price }}</div>
                                </div>
                                <div class="home-newCourse-item-hospital">{{ item.company }}</div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-prev" slot="button-prev"><i class="iconfont icon-07jiantouxiangzuofill"></i></div>
                <div class="swiper-button-next" slot="button-next"><i class="iconfont icon-07jiantouxiangyoufill"></i></div>
            </div>
            <see-more path="/lct"></see-more>
        </div>
        <!-- 热门课程 -->
        <div class="home-hotCourse">
            <plate-title title="热门课程" desc="每个视频攻破一个实用知识点/突破你的知识盲区"></plate-title>
            <div class="home-hotCourse-main">
                <hot-course-item v-for="(item,index) in courseListHot" :key="item.cid" :item="item" @click.native="toPath(`/lct/${item.cid}/detail`)"/>
            </div>
            <see-more path="/lct"></see-more>
        </div>
        <!-- 最新资讯 -->
        <div class="home-actciles">
            <plate-title title="最新资讯" desc="站在资讯最前沿/让您的信息不再滞后"></plate-title>
            <div class="home-actciles-main">
                <div class="home-actciles-item" v-if="Information_new.length>0">
                    <router-link :to="`/actciles/${Information_new[0].id}/detail`" class="home-actciles-example">
                        <div class="home-actciles-example-img"><img :src="Information_new[0].img"/></div>
                        <div class="home-actciles-example-info">
                            <div class="home-actciles-example-title">
                                {{ Information_new[0].title }}
                            </div>
                            <div class="home-actciles-example-desc">{{ Information_new[0].intro }}</div>
                        </div>
                    </router-link>

                    <div class="home-actciles-more">
                        <div class="home-actciles-more-item" v-for="(item,index) in Information_new" :key="item.id" v-if="index<4">
                            <router-link :to="`/actciles/${item.id}/detail`">
                                {{ item.title }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="home-actciles-item" v-if="Information_new.length>5">
                    <router-link :to="`/actciles/${Information_new[5].id}/detail`" class="home-actciles-example">
                        <div class="home-actciles-example-img"><img :src="Information_new[5].img"/></div>
                        <div class="home-actciles-example-info">
                            <div class="home-actciles-example-title">
                                {{ Information_new[5].title }}
                            </div>
                            <div class="home-actciles-example-desc">{{ Information_new[5].intro }}</div>
                        </div>
                    </router-link>

                    <div class="home-actciles-more">
                        <div class="home-actciles-more-item" v-for="(item,index) in Information_new" :key="item.id" v-if="index>4 && index<9">
                            <router-link :to="`/actciles/${item.id}/detail`">
                                {{ item.title }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <see-more path="/actciles"></see-more>
        </div>
        <!-- 关于我们 -->
        <div class="home-about">
            <plate-title :about="true" title="关于我们" desc="以赋能泌尿男科医护为核心，推动泌尿男科产业发展，助力健康中国2030"></plate-title>
            <div class="home-about-main">
                <div class="home-about-title">泌尿外科医生学习联盟简介</div>
                <div class="home-about-desc" v-html="about.intro"></div>

                <see-more :about="true" path="/aboutUs"></see-more>

                <div class="home-about-stat">
                    <div class="home-about-stat-item">
                        <div class="home-about-stat-num"><countTo :startVal='count.followersNum.to' :endVal='count.followersNum.en' :duration='5000' style="text-align: right"></countTo>+</div>
                        <div class="home-about-stat-info">{{ about.followersNum.text }}</div>
                    </div>
                    <div class="home-about-stat-item">
                        <div class="home-about-stat-num"><countTo :startVal='count.followersRatio.to' :endVal='count.followersRatio.en' :duration='5000'></countTo>%+</div>
                        <div class="home-about-stat-info">{{ about.followersRatio.text }}</div>
                    </div>
                    <div class="home-about-stat-item">
                        <div class="home-about-stat-num"><countTo :startVal='count.source.to' :endVal='count.source.en' :duration='5000'></countTo>%+</div>
                        <div class="home-about-stat-info">{{ about.source.text }}</div>
                    </div>
                    <div class="home-about-stat-item">
                        <div class="home-about-stat-num"><countTo :startVal='count.articleNum.to' :endVal='count.articleNum.en' :duration='5000'></countTo>+</div>
                        <div class="home-about-stat-info">{{ about.articleNum.text }}</div>
                    </div>
                    <div class="home-about-stat-item">
                        <div class="home-about-stat-num"><countTo :startVal='count.coupeNum.to' :endVal='count.coupeNum.en' :duration='5000'></countTo>+</div>
                        <div class="home-about-stat-info">{{ about.coupeNum.text }}</div>
                    </div>
                    <div class="home-about-stat-item">
                        <div class="home-about-stat-num"><countTo :startVal='count.session.to' :endVal='count.session.en' :duration='5000'></countTo>+</div>
                        <div class="home-about-stat-info">{{ about.session.text }}</div>
                    </div>
                    <!--<div class="home-about-stat-item">
                        <div class="home-about-stat-num"><countTo :startVal='count.groupNum.to' :endVal='count.groupNum.en' :duration='5000'></countTo>+</div>
                        <div class="home-about-stat-info">{{ about.groupNum.text }}</div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import countTo from 'vue-count-to';

    import Search from '@/utils/search'
    export default {
        data() {
            return {
                swiperOption: {
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: true // 手动切换之后继续自动轮播
                    },
                    loop: true
                },
                swiperOption2: {
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    slidesPerView: 2,
                    slidesPerColumn: 2,
                    spaceBetween: 25,
                    autoplay: {
                        delay: 6000,
                        disableOnInteraction: true // 手动切换之后继续自动轮播
                    },
                },

                Information_hot1: [], // 热门资讯
                Information_hot2: [], // 热门资讯
                liveList: [], // 直播课程
                payCourseList: [], // 付费课程
                newCourseList: [], // 最新课程
                courseListHot: [], // 热门课程
                Information_new: [], // 最新资讯
                count: {
                    followersNum: {
                        to: 0,
                        en: 0  // 45000+
                    },
                    followersRatio: {
                        to: 0,
                        en: 0  // 80%+
                    },
                    source: {
                        to: 0,
                        en: 0  // 97%+
                    },
                    articleNum: {
                        to: 0,
                        en: 0  // 1630+
                    },
                    session: {
                        to: 0,
                        en: 0  // 350
                    },
                    coupeNum: {
                        to: 0,
                        en: 0  // 400+
                    },
                    groupNum: {
                        to: 0,
                        en: 0  // 400+
                    }
                },
            }
        },
        components: { countTo },
        computed: {
            about() {
                return this.$store.state.about
            }
        },
        created() {
            Search.$on('search',data => {
                console.log(data)   //{show:true}
            })
        },
        mounted() {
            this.getHomeData();
            window.addEventListener("scroll",this.showbtn,true);
        },
        methods: {
			toLive(item){
				console.log(item)
				if(item.live_state==3) return this.$message({ message: item.live_title, type: 'warning'});
				this.toPath((item.live_state == 1 || item.live_state == 2)?`/seminars/${item.id}/detail`:`/seminars/${item.vid || item.id}/details`)
			},
            getHomeData() {
                this.$axios.post(this.apiUrl.homeData).then(res => {
                    this.Information_hot1 = res.data.Information_hot1;// 热门资讯
                    this.Information_hot2 = res.data.Information_hot2;// 热门资讯
                    this.liveList = res.data.live;// 直播课程
                    this.payCourseList = res.data.courseList;// 付费课程
                    this.newCourseList = res.data.courseList_new;// 最新课程
                    this.courseListHot = res.data.courseList_hot;// 热门课程
                    this.Information_new = res.data.Information_new;// 最新资讯
                })
            },
            toPath(url) {
                this.$router.push({
                    path: url
                })
            },
            showbtn(){
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                // console.log(scrollTop)
                if(scrollTop>3700) {
                    this.count.followersNum.en = this.about.followersNum.num;
                    this.count.followersRatio.en = this.about.followersRatio.num;
                    this.count.source.en = this.about.source.num;
                }
                if(scrollTop>3800){
                    this.count.articleNum.en = this.about.articleNum.num;
                    this.count.session.en = this.about.session.num;
                    this.count.groupNum.en = this.about.groupNum.num;
                    this.count.coupeNum.en = this.about.coupeNum.num;
                }
            },
        }
    }
</script>

<style scoped lang="scss">
</style>
